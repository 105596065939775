<template>
<v-container fluid fill-height style="background: #6A7881">
	<v-layout flex flex-column align-center justify-center>
		<v-card width="400" class="mx-auto mt-5"  color="white">
		<v-card-title >
			<v-col class="text-center">
				<img width="120" alt="Locmarine Telematic" src="@/assets/logo.png">
			</v-col>
		</v-card-title>
		<v-card-text>
			<v-form v-model="formValidity">
				<v-text-field
					label="User"
					prepend-icon="mdi-account-circle"
					:rules="emailRules"
					v-model="email"
					required
					color="accent"
				/>
				<v-text-field
					label="Password"
					type="Password"
					prepend-icon="mdi-lock"
					:rules="passwordRules"
					v-model="password"
					required
					color="accent"
				/>
				<v-card-actions>
					<v-spacer></v-spacer>
						<v-btn
							@click.stop="login({email:email,password:password})"
							color="accent"
							class="white--text"
							:loading=loading
						>
							INGRESAR
						</v-btn>

				</v-card-actions>
			</v-form>
		</v-card-text>
	</v-card>
	</v-layout>
	<!-- <Notification /> -->
	<v-snackbar
		top
		right
		:timeout="alert.timeout"
		:color="alert.color"
		v-model="alert.show">
			{{ alert.message }}
	</v-snackbar>
</v-container>
</template>
<script>
import axios from 'axios';
import { mapState, mapActions, mapGetters } from "vuex";
import {db} from '@/main';
export default {
  name: 'Login',
  components: {

	},
	created(){
		this.$store.commit('SET_LOADED', true, { root: true })
	},
	data() {
		return {
			loading: false,
			formValidity: false,
			email: '',
			password: '',
			emailRules: [
				value => !!value || 'El correo electrónico es requerido.',
				value => value.indexOf('@') !== 0 || 'El correo electrónico debe tener un nombre de usuario',
				value => value.includes('@') || 'El correo electrónico debe incluir el símbolo @',
				value => value.indexOf('.') - value.indexOf('@') > 1 || 'El correo electrónico debe contener un nombre de dominio válido',
				value => value.indexOf('.') <= value.length - 3 || 'El correo electrónico debe contener una extensión de dominio válido'
			],
			passwordRules: [
				value => value.length >= 6 || 'Contraseña es requerida',
				value => !!value || 'Contraseña es requerida',
			],
		}
	},
	methods: {
		...mapActions(['loginUser','checkUser','logOut','saveToken']),
		login(user){
			let me=this
				this.loading = true;
				this.loginUser(user)
					.then(data =>{
						if ( data.code != null && data.code == 'auth/user-not-found' ) {
							me.$store.commit('SET_ALERT_MESSAGE', {
								show: true,
								color: 'error',
								message: 'User not found !!',
								timeout: 3000
							},{root: true});
							me.loading = false;
						} else {
							axios.post('record/login',{
								imei: process.env.VUE_APP_DEFAULT_IMEI, code: '2402'
							}).then( response => { return response.data }).then(data =>{
								this.saveToken(data.tokenReturn);
								this.$router.push({name: 'AdminDashboard'});
							})
						}
					})
					.catch((e)=>{
						//console.log(e)
						this.$store.commit('SET_ALERT_MESSAGE', {
								show: true,
								color: 'error',
								message: 'Los credenciales no existen, verifique y vuelva a intentar !!',
								timeout: 3000
							},{root: true});
							this.loading = false;
					})
			}
	},
	computed: {
		...mapState(['alert', 'loaded' ]),
	}
}
</script>