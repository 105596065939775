import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login.vue'
import Dealer from '../views/Dealer.vue'
import AdminDashboard from '../views/admin/AdminDashboard.vue'
import AdminDevices from '../views/admin/AdminDevices.vue'
import AdminDealers from '../views/admin/AdminDealers.vue'
import AdminOrders from '../views/admin/AdminOrders.vue'
import AdminSetting from '../views/admin/AdminSetting.vue'
import AdminInitialize from '../views/admin/AdminInitialize.vue'
import AdminShowInputsDevice from '../views/admin/AdminShowInputs.vue'
import AdminEditInputs from '../views/admin/AdminEditInputs.vue'
import AdminLabels from '../views/admin/AdminLabels.vue'
import PrintLabelQr from '../views/admin/PrinterLabelQr.vue'
import PrinterLabelSerial from '../views/admin/PrinterLabelSerial.vue'
import AdminLogTracking from '@/views/admin/AdminLogTracking.vue'
import AdminDevices24Offline from '@/views/admin/AdminDevices24Offline.vue'
import AdminDevicesUninstalled from '@/views/admin/AdminDevicesUninstalled.vue'
import store from "@/store";

Vue.use(VueRouter)

  const routes = [
    {
      path: "/about",
      name: "About",
      component: () =>
        import(/* webpackChunkName: "about" */ "../views/About.vue"),
    },
    {
      path: "/",
      name: "Login",
      component: Login,
      beforeEnter: (to, from, next) => beforeLogin(to, from, next),
    },
    {
      path: "/dealer/:id",
      name: "Dealer",
      component: Dealer,
    },
    {
      path: "/admin",
      name: "AdminDashboard",
      component: AdminDashboard,
      meta: { requiresAuth: true },
      children: [
        {
          path: "/admin/dealers",
          name: "AdminDealers",
          component: AdminDealers,
        },
        {
          path: "/admin/log-tracking/:imei",
          name: "AdminLogTracking",
          component: AdminLogTracking,
        },
        {
          path: "/admin/devices",
          name: "AdminDevices",
          component: AdminDevices,
        },
        {
          path: "/admin/initialize",
          name: "AdminInitialize",
          component: AdminInitialize,
        },
        {
          path: "/admin/devices/24-offline",
          name: "AdminDevices24Offline",
          component: AdminDevices24Offline,
        },
        {
          path: "/admin/devices/actives-uninstalled",
          name: "AdminDevicesUninstalled",
          component: AdminDevicesUninstalled,
        },
				{
					path: "/admin/device/inputs/:imei",
					name: "AdminShowInputs",
					component: AdminShowInputsDevice,
				},
				{
					path: "/admin/device/inputs/edit",
					name: "AdminEditInputs",
					props: { data: Object },
					component: AdminEditInputs,
				},
        {
          path: "/admin/orders",
          name: "AdminOrders",
          component: AdminOrders,
        },
        {
          path: "/admin/setting",
          name: "AdminSetting",
          component: AdminSetting,
        },
        {
          path: "/admin/labels/:imei/:serial",
          name: "AdminLabels",
          component: AdminLabels,
        },
        {
          path: "/label/qr/:imei/:serial",
          name: "PrintLabelQr",
          component: PrintLabelQr,
        },
        {
          path: "/label/qr/:serial/",
          name: "PrinterLabelSerial",
          component: PrinterLabelSerial,
        },
      ],
    },
  ];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})


const beforeLogin = (to, from, next) => {
	const user = store.state.authModule.user;
  if (user !== null) {
		next({ path: "/admin" });
  } else {
		next();
  }
};

router.beforeEach((to, from, next) => {
	const lockedRoute = to.matched.some(record => record.meta.requiresAuth);
	const user = store.state.authModule.user;
	if (lockedRoute === true && user === null) {
		next({ name: 'Login' })
	} else {
		next()
	}

})

export default router
