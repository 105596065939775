<template>
<v-row justify="center">
		<div class="etiqueta1 pt-5">	
			<div class="text-center">
				<img class="mt-3" src="/img/imei-activation-number.png" width="420px"/>
				<h1 class="font-weight-black">{{ imeiFormat() }}</h1>
					<img src="/img/input-numbers.png" width="300px"/>
			</div>
			<div class="d-flex justify-space-around mt-2">
				<div>
					<img class="mt-2" src ="/img/logo-locmarine.png" width="300"/>
					<div class="caption text-right">SN: {{serial }} </div>
				</div>
				<div>
					<div class="qr2">
						<qr-code 
							:size="100"
							:text="imei"
							color="#000"
							bg-color="#fff" 
							error-level="L" />								
					</div>	
				</div>
			</div>
		</div>
</v-row>
</template>

<script>
	export default {
		name: 'PrintLabelQr',
		mounted(){
			setTimeout(() => {  window.print(); }, 3000);
		},
		methods: {
			imeiFormat(){
				const imeiFull = this.$route.params.imei;

				const imeiSpace = `${imeiFull.substring(0, 4)} ${imeiFull.substring(4, 8)} ${imeiFull.substring(8, 12)} ${imeiFull.substring(12,15)}`;

				return imeiSpace;
			},
		},
		computed: {
			imei(){
				return this.$route.params.imei;
			},
			serial(){
				return this.$route.params.serial;
			}
		}
	}
</script>

<style lang="scss">
	.etiqueta1 {
		width: 455px;
		height: 270px;
		border-style: solid;
		border: 1 px;
		line-height: 1.0 !important;
		border-style: dashed;		
	}

	h1 {
		font-size: 2.700rem !important;
    // font-weight: 900 !important;
	}
	.font_imei {
		text-align: center;
		font-size: 2.45rem !important;
    font-weight: 900 !important;
	}
	.qr {
		float: right;
		margin-right: 20px;
		position: relative;
		margin-top: -10px;
	}

</style>