import { dbFb, dbFt } from "@/main";

export const namespaced = true;

export const state = () => ({
  device: {
    id: null,
    dealer_id: '',
    dealer_name: '',
    imei: '',
    active: true,
    expiration: ''
  },
  devices: [],
  info: {
    boatname: '',
    code: 0,
    country: '',
    email: '',
    firstname: '',
    lastname: '',
    phone: '',
  },
  dialog: false,
  editMode: false,
  assaignDialog: false,
  infoDialog: false,
})

export const mutations = {
  SET_INFO: (state, payload) => {
    state.info = payload
  },
  SET_DEVICE: (state, payload) => {
    state.device = payload
  },
  SET_DEVICES: (state, payload) => {
    state.devices = payload
  },
  SET_DEVICE_DIALOG: (state, data) => {
    state.dialog = !state.dialog;
    state.editMode = data.editMode;
    state.device = data.device;
  },
  SET_ASSIGN_DIALOG: (state, data) => {
    state.assaignDialog = !state.assaignDialog;
    state.device = data.device;
  },
  SET_INFO_DIALOG: (state, data) => {
    state.infoDialog = !state.infoDialog;
    state.info = data.info;
  },
}

export const actions = {
  setInfo({ commit }, imei) {
    dbFb.ref(`/${imei}/info`).once('value')
      .then( function(snap){
        commit("SET_INFO", snap.val());
      })
  },
  setDevice({ commit }, payload) {
    commit("SET_DEVICE", payload);
  },
  setDealer({ commit }) {
    commit("SET_DEVICE", payload);
  },
  toggleDeviceDialog({ commit }, data) {
    commit("SET_DEVICE_DIALOG", data);
  },
  getDevices({ commit, dispatch }) {
    return new Promise(resolve => {
      dbFt.collection("devices").onSnapshot(function (querySnapshot) {
        let devs = [];
        querySnapshot.forEach((doc) => devs.push(doc.data()));
        commit("SET_DEVICES", devs);
        commit("SET_LOADED", true, { root: true });
        resolve();
      });
    });
	},
}

export const getters = {
  getterInfo: (state) => {
    return state.info;
  },
  getterDevice: (state) => {
    return state.device;
  },
  getterDevices: (state) => {
    return state.devices;
  },
  devicesDialog: (state) => {
    return state.dialog;
  },
  deviceForEdit: (state) => {
    return state.device;
  },
  deviceDialogEditMode: (state) => {
    return state.editMode;
  },
}