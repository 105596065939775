import Vue from "vue";
import App from "./App.vue";
import axios from 'axios'
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import './plugins/base'

import * as VueGoogleMaps from 'vue2-google-maps';
import * as VueGoogleMarker from 'vue2-google-maps';
Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_APIKEY,
    libraries: 'places',
  }
})

import VuetifyConfirm from "vuetify-confirm";
Vue.use(VuetifyConfirm, { vuetify });

import ImageUploader from "vue-image-upload-resize";
Vue.use(ImageUploader);

import VueQRCodeComponent from "vue-qr-generator";
Vue.component("qr-code", VueQRCodeComponent);

import JsonExcel from "vue-json-excel";
Vue.component("downloadExcel", JsonExcel);



// import { Icon } from "leaflet";
// import "leaflet/dist/leaflet.css";
// delete Icon.Default.prototype._getIconUrl;

// Icon.Default.mergeOptions({
//   iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
//   iconUrl: require("leaflet/dist/images/marker-icon.png"),
//   shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
// });

Vue.use(require("vue-moment"));

var firebase = require("firebase/app");
require("firebase/auth");
require("firebase/database");
require("firebase/firestore");
require("firebase/storage");
import firebaseConfig from "@/plugins/firebase";
firebase.initializeApp(firebaseConfig);

export const dbFt = firebase.firestore(); //firestore
export const dbFb = firebase.database(); //realtime
export const storage = firebase.storage();
export const auth = firebase.auth();

axios.defaults.baseURL = `${process.env.VUE_APP_BASE_URL}/api/`

Vue.config.productionTip = false;

firebase.auth().onAuthStateChanged((user) => {
  if (user) {
    store.dispatch("checkUser",{ email: user.email, uid: user.uid },{ root: true });
  } else {
    store.dispatch("checkUser", null, { root: true });
  }


  new Vue({
    router,
    store,
    vuetify,
    render: (h) => h(App),
    mounted() {}, // end mounted
  }).$mount("#app");
}); // end auth
