<template>
	<div class="etiqueta2 background_etiqueta2 mx-auto font_container">
		<div class="">
			<div class="font_serial">
				<vue-barcode  :value="serial" :options="{ displayValue: true }"></vue-barcode>

			</div>
		</div>

	</div>
</template>

<script>
	import VueBarcode from '@chenfengyuan/vue-barcode';
	export default {
		name: 'PrinterLabelSerial',
		components: {VueBarcode},
		mounted(){
			setTimeout(() => {  window.print(); }, 3000);
		},
		methods: {
		},
		computed: {
			serial(){
				return this.$route.params.serial;
			}
		}
	}
</script>

<style lang="scss">
	.etiqueta2 {
		width: 728px;
		height: 478px;
		border-style: solid;
		border: 1 px;
		border-color: rgb(191, 194, 194);
		border-style: dashed;
		
	}
	.background_etiqueta2 {
		background-image: url("https://locmarine-us.web.app/img/etiqueta-2.jpg");
	}
	.font_container {
		position: relative;
	}
	.font_serial {
		position: absolute;
    bottom: 0;
		font-size: 20px;
		margin-bottom: 34px;
		margin-left: 45px;
	}

</style>