<template>
  <div>
    <v-container fluid class="accent py-0 mt-0" v-if="loaded">
      <!-- Breadcrumb -->
      <v-row class="mb-2 pt-4 secondary">
        <v-col cols="12" color="blue-grey" class="px-5 white--text text-center">
          <v-icon @click="goDevices()" class="mr-2 white--text" large >mdi-chevron-double-left</v-icon>
          <span class="font-weight-bold">IMEI: </span>{{ imei }} |
          {{ timestamp | moment('MM/DD/YYYY, H:mm:ss') }} |
          <span class="font-weight-bold">Last update:</span> {{ timestamp | moment('from', 'now', true) }}
        </v-col>
      </v-row>

      <v-row justify="center">
        <v-col cols="12" sm="7">
          <!-- Map -->
          <show-gmap-actual :imei='imei' />
        </v-col>
        <v-col cols="12" sm="3">
          <!-- Qr -->
          <showQr/>
          <!-- Simcard -->
          <showSimcardBox />
          <!-- Reset backup -->
          <reset-backup-power :imei='imei' class="mt-1" />
        </v-col>
      </v-row>

      <v-divider class="my-2 mx-2" />

      <v-row justify="center">
        <v-col cols="12" sm="5">
          <!-- Inputs List -->
          <show-inputs-list :imei='imei' :inputs='inputs' :loaded='loaded' />
          <v-divider class="my-2" />
          <!-- Alerts List -->
          <show-alerts-list :inputs='inputs' :alerts='alerts' :loading='loaded' />
        </v-col>
        <v-col cols="12" sm="5">
          <!-- Info Customer -->
          <showInfo />
          <v-divider class="my-2 mx-2" />
          <!-- Expiration date -->
          <showExpiration />
          <v-divider class="my-2" />
          <!-- Show Due Invoice -->
          <showDue />
          <v-divider class="my-2" />
          <!-- Notes -->
          <showPrivateNotes />

        </v-col>
      </v-row>

      <v-divider class="my-2 mx-2" />

      <v-row justify="center">
        <v-col cols="12" sm="5">
          <!-- Distribuitor -->
          <showDistribuitor />
          <v-divider class="my-2" />
          <!-- Dealer -->
          <showDealer />
        </v-col>
        <v-col cols="12" sm="5">
          <!-- Send PUSH -->
          <send-fcm />
          <v-divider class="my-2" />
          <!-- Hub Installer-->
          <showHubInstallerBox />
          <v-divider class="my-2" />
          <!-- Show Billing -->
          <showBillingBox />
          <v-divider class="my-2" />
          <!-- Show Status -->
          <showActiveBox />
          <v-divider class="my-4"/>
        </v-col>
      </v-row>
      <v-divider class="my-2 mx-2" />
      <v-row justify="center" class="pb-5">
        <v-col cols="12" sm="10">
          <!-- Show Put Command -->
          <putCommand />
        </v-col>
      </v-row>
      <v-divider class="my-2 mx-2" />
      <v-row justify="center" class="pb-5">
        <v-col cols="12" sm="10">
          <!-- Advanced actions -->
          <showAdvanced />
        </v-col>
      </v-row>


    </v-container>
    <v-container v-else fill-height>
      <v-layout flex align-center justify-center>
        <v-progress-circular indeterminate :size="100" :widht="45" color="secondary" />
      </v-layout>
    </v-container>
  </div>
</template>

<script>
  import _ from 'underscore';
  import { dbFb } from '@/main';
  export default {
    name: 'AdminShowInputs',
    components: {
    showActiveBox: () => import('@/components/admin/ShowActiveBox.vue'),
    showQr: () => import("@/components/admin/ShowQr.vue"),
    showExpiration: () => import("@/components/admin/ShowExpiration.vue"),
    showDealer: () => import("@/components/admin/ShowDealer.vue"),
    showDistribuitor: () => import("@/components/admin/ShowDistribuitor.vue"),
    showInfo: () => import("@/components/admin/ShowInfo.vue"),
    showAdvanced: () => import("@/components/admin/ShowAdvanced.vue"),
    showPrivateNotes: () => import("@/components/admin/ShowPrivateNotes.vue"),
    sendFcm: () => import("@/components/admin/SendFcm.vue"),
    showInputsList: () => import("@/components/admin/ShowInputsList.vue"),
    showAlertsList: () => import("@/components/admin/ShowAlertsList.vue"),
    showGmapActual: () => import("@/components/admin/ShowGmapActual.vue"),
    resetBackupPower: () => import("@/components/admin/ResetBackupPower.vue"),
    putCommand: () => import("@/components/admin/PutCommand.vue"),
    showBillingBox: () => import("@/components/admin/ShowBillingBox.vue"),
    showHubInstallerBox: () => import("@/components/admin/ShowHubInstallerBox.vue"),
    showDue: () => import('@/components/admin/ShowDue.vue'),
    showSimcardBox: () => import("@/components/admin/ShowSimcardBox.vue"),
},

    created(){
      this.getData();
    },
    data: () => ({
      saving: false,
      loaded: false,
      timestamp: 0,
      inputs: [],
      alerts: [],
      currentMidx: null,
    }),
    methods: {
      goDevices(){
         this.$router.push({name: "AdminDevices"})
      },
      async getData(){
        try {
          await dbFb.ref(`/${this.imei}/data`).on("value", (snapshot) => {
            if (snapshot.val() != null){
              this.timestamp = snapshot.val().timestamp
            } else {
              this.timestamp = new Date().getTime() / 1000
            }
          });
          await dbFb.ref(`/${this.imei}/idata`).on("value", (snapshot) => {
            this.inputs = [];
            if (snapshot.val() != null){
              snapshot.forEach((doc) =>{
                if(doc.val().id != null) {
                  this.inputs.push(doc.val())
                } else {
                  if ( doc.val()?.anchor != null  ){
                    this.inputs.push({code: 'anchor', name: 'Geofence', orderlist: 0, ...doc.val()})
                  }
                }

              });
              this.inputs.sort((a, b) => (a.orderlist > b.orderlist) ? 1 : -1)
            }
          });
          await dbFb.ref(`/${this.imei}/alerts`).on("value", (snapshot) => {
            this.alerts = [];
            if (snapshot.val() != null){
              snapshot.forEach((doc) =>{
                this.alerts.push({id: doc.id , ...doc.val()})
              });
            }
          });
          this.$store.commit('SET_QR',{show_qr: false, value_qr: this.imei}, {root: true});
        } catch (error) {
          console.log(error)
          this.loaded = true
        } finally{
          this.loaded = true
        }
      },

    },
    computed: {
      imei(){
        return this.$route.params.imei;
      },
    }

  }
</script>

<style>

.v-list--two-line .v-list-item, .v-list-item--two-line {
    min-height: 34px;
    border-bottom: 1px dotted grey;
}

</style>