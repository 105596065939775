import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
	theme: {
		themes: {
			light: {
				primary: '#3286C5',
				secondary: '#6A7881',
				accent: '#37474F',
				info: '#F57C00',
				error: '#b71c1c',
				backBlueDarken: '#546E7A',
				backBlueLight: '#CFD8DC',
				backBlueLightBody: '#90A4AE',
			},
		},
	},
});

