<template>
  <v-main>
    <v-container>
      <v-layout flex flex-column align-center justify-center style="height:50vh;" v-if="!loaded">
          <v-progress-circular indeterminate :size="150" :width="20" color="secondary" />
      </v-layout>
      <v-row v-else>
        <v-col cols="12">
          <v-row class="align-center">
            <v-col cols="12" sm="3">
              <h2 class="white--text">initialize Devices</h2>
              <h4 class="white--text">{{ myDevices.length }} devices</h4>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                label="Search IMEI"
                filled
                dense
                dark
                v-model="search"
              />
            </v-col>
            <v-col cols="12" sm="3" class="text-right">
              <v-btn
                class="mx-2"
                fab
                dark
                color="primary"
                @click.prevent="newDevice()"
              >
                <v-icon size="38" dark>
                  mdi-plus
                </v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="6" sm="3" md="2" v-for="item in filterDevices" :key="item.imei">
          <v-list two-line dark>
            <v-list-item @click="showDetail(item)" class="pointer">
              <v-list-item-subtitle class="text-center">
                <h3>{{ item.imei }}</h3>
                <p>{{ item.sn}}</p>
              </v-list-item-subtitle>
            </v-list-item>
          </v-list>
        </v-col>
      </v-row>


      <!-- New device -->
      <v-dialog
        v-model="dialogNew"
        max-width="290"
        dark
        persistent
      >
        <v-card>
          <v-card-title class="text-h5">
            New Device
          </v-card-title>

          <v-card-text>

            <v-form ref="form" v-model="valid"  @keyup.native.enter="valid && submit($event)">
              <v-text-field
                ref="firmware"
                v-model="firmware"
                label="FIRMWARE"
                clearable
              />
              <v-text-field
                ref="config"
                v-model="config"
                label="CONFIG"
                clearable
              />
              <v-text-field
                ref="imei"
                v-model="imei"
                :counter="15"
                :rules="imeiRules"
                label="IMEI"
                required
              />
              <v-text-field
                v-model="sn"
                :counter="10"
                :rules="snRules"
                label="SN"
                required
              />
            </v-form>

          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="red darken-1"
              text
              @click="dialogNew = false"
            >
              Cancel
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-snackbar
        v-model="snackbar"
        :timeout="2000"
        :color="snackbarColor"
        rounded="pill"
      >
        {{snackbarMessage}}
      </v-snackbar>
      <!-- .New device -->

      <!-- Show Detail -->
      <v-dialog
        v-model="dialogDetail"
        max-width="290"
        dark
      >
        <v-card>
          <v-card-title class="text-h5">
            Detail
          </v-card-title>

          <v-card-text>
           <div v-html="device" />
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="red darken-1"
              text
              @click="dialogDetail = false"
            >
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- .Show Detail -->

    </v-container>

  </v-main>
</template>

<script>
  import { dbFt } from '@/main';
  import formatMixin from '@/mixins/formatMixin'
  import { mapState, mapActions, mapGetters} from 'vuex';
  export default {
    name: 'AdminInitialize',
    data: ()=>({
      snackbar: false,
      snackbarMessage: 'Locmarine Default Message',
      snackbarColor: 'red darken-1',
      device: '',
      dialogDetail: false,
      search: '',
      dialogNew: false,
      valid: false,
      imeiRules: [
        v => !!v || 'IMEI is required',
        v => (v && v.length == 15) || 'IMEI must be 15 characters',
      ],
      snRules: [
        v => !!v || 'SN is required',
        v => (v && v.length == 10) || 'SN must be 10 characters',
      ],
      imei: '',
      sn: '',
      firmware: '',
      config: '',
      today: null
    }),
    mounted(){
      this.getData()
    },
    methods: {
      ...mapActions('deviceModule',[ 'getDevices' ]),
      async getData() {
        try {
          const response = await this.getDevices()
        } catch (error) {
          console.log(error)
        }
      },
      showDetail(item){
        const obj = Object.entries(item)
        this.device = ''
        for (const [key,value] of obj){
          this.device += `<br/><strong>${key}</strong>: <br/>${value}`
        }

        //this.device = JSON.stringify(item)
        this.dialogDetail = true
      },
      newDevice(){
        this.imei = ''
        this.sn = ''
        // this.firmware = '',
        // this.config = '',
        this.dialogNew = true
      },
      async submit(event){
        let me=this
        const d = new Date().toLocaleString('en-US', { timeZone: 'America/New_York' })
        this.today = new Date(d).getTime()/1000

        // Check if IMEI exists
        const docRef = await dbFt.collection("devices").doc(me.imei).get();
        if (docRef.exists){
          me.snackbarColor = "red darken-1"
          me.snackbarMessage = "Device exists, this device cannot be initialized !!!"
          me.snackbar = true
          setTimeout(() => {
            me.$refs.imei.focus()
          })
          return
        }

        // If IMEI no exists
        await dbFt.collection('devices').doc(me.imei)
        .set({
          active: true,
          dealer_id: '1',
          dealer_name: 'Locamerine Telematic',
          imei: me.imei,
          sn: me.sn,
          config: me.config,
          firmware: me.firmware,
        })
        .then( docRef => {
          me.$refs.form.resetValidation()
          me.imei = ""
          me.sn = ""
          me.snackbarColor = "green darken-1"
          me.snackbarMessage = "Device initialized successful"
          me.snackbar = true
          setTimeout(() => {
            me.$refs.imei.focus()
          })

        });

      },
      closeDialogNew(){
        me.getData()
        this.imei = ""
        this.sn = ""
        this.dialogNew = false
      }
    },
    computed: {
      ...mapState(['loaded']),
      ...mapState('deviceModule',['devices']),
      myDevices(){
        return this.devices || []
      },
      filterDevices(){
        return this.myDevices.filter(d => {
          return d.imei.includes(this.search);
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
.pointer {
  cursor: pointer;
}
</style>