import { dbFt } from "@/main";

export const namespaced = true;

export const state = () => ({
  dealer: {
    id: null,
    name: '',
    email: '',
    address: '',
    phone: '',
    logo_url: '',
    logo_name: '',
    website: '',
    password: '',
    active: true
  },
  dealers: [],
  dialog: false,
  editMode: false,
})

export const mutations = {
  SET_DEALER: (state, payload) => {
    state.dealer = payload
  },
  SET_DEALERS: (state, payload) => {
    state.dealers = payload
  },
  SET_DEALER_DIALOG: (state, data) => {
    state.dialog = !state.dialog;
    state.editMode = data.editMode;
    state.dealer = data.dealer;
  },
}

export const actions = {
  setDealer({ commit }) {
    commit("SET_DEALER", payload);
  },
  getDealer({ commit }, dealerId) {
    return new Promise( resolve => {
      dbFt.collection("dealers").doc(dealerId).get().then( (snap)=>{
        if (snap.exists) {
          commit("SET_DEALER", snap.data())
          commit("SET_LOADED", true, { root: true });
          resolve();
        } else {
          commit("SET_DEALER", {id: null,});
          commit("SET_LOADED", true, { root: true });
          resolve();
        }
      })
    })
  },
  getDealers({ commit, dispatch }) {
    return new Promise(resolve => {
      dbFt.collection("dealers").onSnapshot(function(querySnapshot) {
        let deals = [];
        querySnapshot.forEach((doc) => deals.push(doc.data()));
        commit("SET_DEALERS", deals);
        commit("SET_LOADED", true, { root: true });
        resolve();
      });
    });
  },
  toggleDealerDialog({ commit }, data) {
    commit("SET_DEALER_DIALOG", data);
  },

}

export const getters = {
  getterDealer: (state) => {
    return state.dealer;
  },
  getterDealers: (state) => {
    return state.dealers;
  },
  dealersDialog: (state) => {
    return state.dialog;
  },
  dealerForEdit: (state) => {
    return state.dealer;
  },
  dealerDialogEditMode: (state) => {
    return state.editMode;
  },
};