<template>
  <v-container class="fill-height" fluid>
      <v-row align="center" justify="center">
        <v-col cols="12" class="text-center">
          <img width="120" alt="Locmarine Telematic" src="@/assets/logo.png">
        </v-col>
        <v-col cols="12" class="text-center">
          <v-icon large>mdi-code-tags</v-icon> DASHBOARD ORDERS... futuro uso
        </v-col>
      </v-row>
    </v-container>
</template>

<script>
  export default {
    
  }
</script>

<style lang="scss" scoped>

</style>