var firebase = require("firebase/app");
import axios from 'axios';
import router from "@/router";

export default {
  state: {
    user: null,
    token: null,
  },
  mutations: {
    setUser(state, payload) {
      state.user = payload;
    },
    SET_TOKEN(state, token){
      state.token = token
    },
  },
  actions: {
    registerUser: ({ commit }, user) => {
      return firebase
        .auth()
        .createUserWithEmailAndPassword(user.email, user.password);
    },
    loginUser: ({ commit }, user) => {
      const name   = user.email.substring(0, user.email.lastIndexOf("@"));
      if (name != 'itadmin'){
        return {code: 'auth/user-not-found', message: 'There is no user record corresponding to this identifier. The user may have been deleted.', a: null}
      }
      return firebase
        .auth()
        .signInWithEmailAndPassword(user.email, user.password);
    },
    checkUser({ commit,dispatch }, payload) {
      if (payload != null) {
        commit("setUser", { email: payload.email, uid: payload.uid });
        const token = localStorage.getItem("token")
        if (token == null){
          axios.post('record/login',{
            imei: process.env.VUE_APP_DEFAULT_IMEI, code: '2402'
          }).then( response => { return response.data }).then(data =>{
            dispatch("saveToken",data.tokenReturn);
          })
        }
      } else {
        commit("setUser", null);
      }
    },
    removeUser({ commit }, uid) {
      firebase
        .auth()
        .delete(uid)
        .then(function() {
          console.log("Successfully deleted user");
        })
        .catch(function(error) {
          console.log("Error deleting user:", error);
        });
    },
    logOff({ commit }) {
      firebase.auth().signOut();
      commit("setUser", null);
      commit("SET_TOKEN", null);
      localStorage.removeItem("token");
      router.push({ name: "Login" });
    },
    saveToken({commit}, token){
      commit("SET_TOKEN", token)
      localStorage.setItem("token", token)
    },
    autoLogin({commit}){
      let token = localStorage.getItem("token");
      if(token) {
        commit("SET_TOKEN", token);
      } else {

      }
    },
  },
  getters: {
    logonUser(state) {
      if (
        state.user === null ||
        state.user === "" ||
        state.user === undefined
      ) {
        return false;
      } else {
        return true;
      }
    },
  },
};
