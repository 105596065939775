import Vue from 'vue'
import Vuex from 'vuex'
import authModule from './modules/auth'
import * as dealerModule from './modules/dealer'
import * as deviceModule from './modules/device'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    authModule,
    dealerModule,
    deviceModule,
  },
  state: {
    loaded: false,
    alert: {
      type: "success",
      show: false,
      message: "",
      color: "primary",
    },
    dialog: false,
    showQr: false,
    valueQr: "",
		dialogLabelQr: false,
    // Stats
    totalDevices: 0,
    devicesActives: 0,
    devicesBilling: 0,
    totalDealers: 0,
    totalDistribuitors: 0,
    distribuitors: [],
    offLineDevices: [],
    codeCeroDevices: [],
    uninstalledDevices: [],
  },
  mutations: {

    SET_DEVICES_MAP(state, payload) {
      state.devicesMap = payload;
    },
    SET_CODE_CERO_DEVICES(state, payload) {
      state.codeCeroDevices = payload;
    },
    SET_UNINSTALLED_DEVICES(state, payload) {
      state.uninstalledDevices = payload;
    },
    SET_OFFLINE_DEVICES(state, payload) {
      state.offLineDevices = payload;
    },
    SET_TOTAL_DEALERS(state, payload) {
      state.totalDealers = payload;
    },
    SET_TOTAL_DISTRIBUITORS(state, payload) {
      state.totalDistribuitors = payload;
    },
    SET_DISTRIBUITORS(state, payload) {
      state.distribuitors.indexOf(payload) === -1 ? state.distribuitors.push(payload) : null;
    },
    SET_TOTAL_DEVICES(state, payload) {
      state.totalDevices = payload;
    },
    SET_TOTAL_DEVICES_ACTIVES(state, payload) {
      state.devicesActives = payload;
    },
    SET_TOTAL_DEVICES_BILLING(state, payload) {
      state.devicesBilling = payload;
    },


    SET_DIALOG_LABEL_QR: (state) => {
			state.dialogLabelQr = !state.dialogLabelQr;
		},
    SET_QR(state, payload) {
      state.showQr = payload.show_qr;
      state.valueQr = payload.value_qr;
    },
    SET_DIALOG(state, payload) {
      state.dialog = payload;
    },
    SET_LOADED(state, payload) {
      state.loaded = payload;
    },
    SET_ALERT_MESSAGE: (state, data) => {
      state.alert.type = data.type;
      state.alert.show = data.show;
      state.alert.message = data.message;
      state.alert.color = data.color;
      setTimeout(() => {
        state.alert.type = "success";
        state.alert.show = false;
        state.alert.message = "";
        state.alert.color = "primary";
      }, data.timeout);
    },
  },
  actions: {
		toggleLabelQrDialog({ commit }, data){
			commit('SET_DIALOG_LABEL_QR', data);
		},

    storeTotalDevices({commit}, data) {
      commit('SET_TOTAL_DEVICES', data);
    }
	},

});
