export default {

		formatDate (date) {
			if (!date) return null

			const [year, month, day] = date.split('-')
			return `${month}/${day}/${year}`
		},

		formatDateDefault (date) {
			if (!date) return null

			const [month, day, year] = date.split('/')
			return `${year}-${month}-${day}`
		},


};