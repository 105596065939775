import { dbFt,dbFb } from '@/main';
import store from "@/store";
import dayjs from 'dayjs'
import axios from 'axios';
	const header = {"Token": localStorage.getItem("token") }
	const configuration = {headers: header}

export default {

	async getImeis(){
		const imeiList = await dbFt.collection("devices").get();
		return imeiList;
	},

	async getWithBilling(devices){
		let cont = 0
		devices.forEach((doc) => {
			const dev = doc.data()
			if (dev.billing != null){
				if (dev.billing){cont += 1}
			}
		})
		return cont
	},

	getActivesDevices(devices){
		const total = devices.length
		let acum = 1
		let actives = 0
		let distribuitors = []
		const now = Math.floor(Date.now() / 1000)
		const hrsAgo24 = dayjs().subtract(24, 'hours').unix()
		const arrayOffline = []

		return new Promise(function (resolve, reject) {
					devices.forEach((doc) => {
						const dev = doc.data()
						if (dev.distribuitor_id != null){
							distribuitors.push(dev.distribuitor_id)
							store.commit('SET_DISTRIBUITORS', dev.distribuitor_id)
						}
						dbFb.ref(`/${dev.imei}/info`).once('value',function(snap){
							if (snap.val() != null){
								const info = snap.val()
								if(snap.val().email != 'NOT SET')	{
									actives = actives+1
									store.commit('SET_TOTAL_DEVICES_ACTIVES', actives)

									// Devices Actives with 24hrs offline
									if ( dev.installer != true ){
										dbFb.ref(`/${dev.imei}/data`).on("value", (snapshot) => {
											const snapData = snapshot.val()
											if (snapData && (snapData.lat > 0) ){
													if ( snapData.timestamp < hrsAgo24 ) {
														arrayOffline.push({
															imei: dev.imei,
															sn: dev.sn,
															dealer_name: dev.dealer_name,
															billing: dev.billing ? true : false,
															expiration: dev.expiration ? dev.expiration : 'NOT SET',
															distribuitor_name: dev.distribuitor_name ? dev.distribuitor_name : 'NOT SET',
															customer: info,
															lastUpdate: dayjs.unix(snapData.timestamp).format('MM-DD-YYYY').toString()
														})
													}
												}
										});
									}

								}
							}
						})
						store.commit('SET_OFFLINE_DEVICES', arrayOffline)
						acum = acum+1
						if ( acum > total){
							store.commit('SET_LOADED', true)
							return resolve(actives)
						}
					})
		})
	},

	async getDevicesWithCodeCero(devices){
		const total = devices.length
		let acum = 1
		let actives = 0
		let distribuitors = []
		const arrayOffline = []

		return new Promise(function (resolve, reject) {
					devices.forEach((doc) => {
						const dev = doc.data()
						if (dev.distribuitor_id != null){
							distribuitors.push(dev.distribuitor_id)
							store.commit('SET_DISTRIBUITORS', dev.distribuitor_id)
						}
						dbFb.ref(`/${dev.imei}/info`).once('value',function(snap){
							if (snap.val() != null){
								const info = snap.val()
								if(snap.val().code == 0)	{
									actives = actives+1
									store.commit('SET_TOTAL_DEVICES_ACTIVES', actives)

										dbFb.ref(`/${dev.imei}/data`).on("value", (snapshot) => {
											const snapData = snapshot.val()
											if (snapData && (snapData.lat > 0) ){
												arrayOffline.push({
													imei: dev.imei,
													sn: dev.sn,
													dealer_name: dev.dealer_name,
													billing: dev.billing ? true : false,
													expiration: dev.expiration ? dev.expiration : 'NOT SET',
													distribuitor_name: dev.distribuitor_name ? dev.distribuitor_name : 'NOT SET',
													customer: info,
													lastUpdate: dayjs.unix(snapData.timestamp).format('MM-DD-YYYY').toString()
												})
												//console.log(arrayOffline)
												store.commit('SET_CODE_CERO_DEVICES', arrayOffline)
											}
										});

								}
							}
						})
						acum = acum+1
						if ( acum > total){
							return resolve(actives)
						}
					})
		})
	},


	async getDealers() {
		const dealers = await dbFt.collection("dealers").get();
		return dealers;
  },

}