<template>
  <v-app class="blue-grey darken-3">
    <v-container v-if="loaded" fluid>

			<v-row justify="center">
				<v-col cols="12">
					<devices-actives-uninstalled @refreshData="getData" v-if="loaded"/>
				</v-col>
			</v-row>
    </v-container>
		<text-loaded :loaded="!loaded" :message="message" />
  </v-app>
</template>

<script>

	import { dbFt,dbFb } from '@/main';
	import dayjs from 'dayjs'

	import axios from 'axios';
	const header = {"Token": localStorage.getItem("token") }
	const configuration = {headers: header}
	import locData from '@/services/data';

	export default {
		name: 'BoardPrincipal',
		components: {
			TextLoaded: () => import('@/components/base/TextLoaded.vue'),
			DevicesActivesUninstalled: () => import('@/components/dashboard/widgets/DevicesActivesUninstalled.vue'),
		},
		mounted(){
			this.getData()
		},
		data: () => ({
			//loaded: true,
			totalDevices: 0,
			message: '',
			loading: true,
			arrayOffline: []
		}),
		methods: {
			async getData(){
				const me=this
				let devices = []
				const token = localStorage.getItem("token")
				const lastDays = dayjs().subtract(2, 'days').unix()

				this.$store.commit('SET_LOADED', false)

				try {
					this.message = "Getting all Devices"
					const imeiList = await locData.getImeis()
					this.message = "Filtering devices with 30 or more logs... Just a moment"
					imeiList.forEach((doc) => {
						const dev = doc.data()

						dbFb.ref(`/${dev.imei}`).once('value',function(snap){

							if (snap.val() != null){
								const dvice = snap.val()
								if (dvice.info.code == 0 && dvice.data.timestamp >= lastDays){
									// console.log(dvice.data.timestamp)
									// console.log(dvice.info.code)
									devices.push({
										imei: dev.imei,
										sn: dev.sn,
										dealer_name: dev.dealer_name,
										billing: dev.billing ? true : false,
										expiration: dev.expiration ? dev.expiration : 'NOT SET',
										distribuitor_name: dev.distribuitor_name ? dev.distribuitor_name : 'NOT SET',
										customer: dvice.info,
										lastUpdate: dayjs.unix(dvice.data.timestamp).format('MM/DD/YYYY')
									})
								}
							}

						})



// 						dbFb.ref(`/${dev.imei}/info`).once('value',function(snap){
// 							if (snap.val() != null){
// 								const info = snap.val()
// 								if(snap.val().code == 0)	{
// 									axios.get(`record/query?imei=${dev.imei}`, {headers: {"Token": token }} )
// 										.then(function (response) {
// 											if (response.data.length >= 30){
// 												//console.log(response.data.length);
// 												devices.push({
// 													imei: dev.imei,
// 													sn: dev.sn,
// 													dealer_name: dev.dealer_name,
// 													billing: dev.billing ? true : false,
// 													expiration: dev.expiration ? dev.expiration : 'NOT SET',
// 													distribuitor_name: dev.distribuitor_name ? dev.distribuitor_name : 'NOT SET',
// 													customer: info,
//
// 												})
// 											}
// 										})
// 										.catch(function (error) {	console.log(error);	});
//
// 								}
// 							}
// 						})
					})
					me.$store.commit('SET_UNINSTALLED_DEVICES', devices)
					this.$store.commit('SET_LOADED', true)

				} catch (error) {
					this.$store.commit('SET_LOADED', true)
					console.log(error)
				}


			}
		},
		computed: {
			loaded(){
				return this.$store.state.loaded
			},
			uninstalledDevices(){
				return this.$store.state.uninstalledDevices
			},
			codeCero(){
				return this.$store.state.codeCeroDevices
			}
		}
	}
</script>
<style>
  .v-card, .v-list {
    border-radius: 0;
  }
</style>