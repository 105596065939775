<template>
  <v-app class="blue-grey darken-3">
    <v-container v-if="loaded" fluid>

			<v-row justify="center">
				<v-col cols="12">
					<devices-actives-offline @refreshData="getData" />
				</v-col>
			</v-row>
    </v-container>
		<main-loaded :loaded="!loaded" />
  </v-app>
</template>

<script>
	import { dbFt,dbFb } from '@/main';
	import locData from '@/services/data';

	export default {
		name: 'BoardPrincipal',
		components: {
			MainLoaded: () => import('@/components/base/MainLoaded.vue'),
			DevicesActivesOffline: () => import('@/components/dashboard/widgets/DevicesActivesOffline.vue'),
		},
		mounted(){
			this.getData()
		},
		data: () => ({
			//loaded: true,
			totalDevices: 0,
			totalDevicesActives: 0,
			totalDevicesBilling: 0,
		}),
		methods: {
			async getData(){
				let me=this
				this.totalDevicesActives = 0

				this.$store.commit('SET_LOADED', false)

				const imeiList = await locData.getImeis()

				const dealers = await locData.getDealers()



				// Total devices
				this.totalDevices = imeiList.size
				this.$store.commit('SET_TOTAL_DEVICES', this.totalDevices)

				// total billing devices
				// this.totalDevicesBilling = await locData.getWithBilling(imeiList.docs)
				// this.$store.commit('SET_TOTAL_DEVICES_BILLING', this.totalDevicesBilling)

				//total actives devices
				this.totalDevicesActives = await locData.getActivesDevices(imeiList.docs)

				//total dealers
				// this.$store.commit('SET_TOTAL_DEALERS', dealers.size)

			}
		},
		computed: {
			loaded(){
				return this.$store.state.loaded
			}
		}
	}
</script>
<style>
  .v-card, .v-list {
    border-radius: 0;
  }
</style>