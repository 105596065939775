var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-sheet',{staticClass:"accent"},[(_vm.loading)?_c('div',[_c('v-container',{staticClass:"mb-12 pb-12 accent"},[_c('v-layout',{attrs:{"flex":"","align-center":"","justify-center":"","column":""}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":250,"width":20,"color":"primary"}}),_c('div',{staticClass:"primary--text"},[_vm._v("Loading...")])],1)],1)],1):_vm._e(),(!_vm.loading)?_c('v-card',{attrs:{"dark":"","color":"secondary","elevation":"0"}},[_c('v-card-text',[_c('GmapMap',{ref:"loc",staticStyle:{"width":"100%","height":"600px"},attrs:{"center":_vm.center,"zoom":12,"options":{
				zoomControl: true,
				mapTypeControl: true,
				mapTypeId: 'hybrid',
				scaleControl: true,
				streetViewControl: false,
				rotateControl: false,
				fullscreenControl: true,
				disableDefaultUi: false,
			}}},[(_vm.path.length > 0)?_c('GmapPolyline',{attrs:{"path":_vm.path,"options":{
					strokeColor: '#FF0000',
					icons: [{ icon: { path: _vm.lineSymbol, scale: 2, }, offset: '0', repeat: '70px' }]
				}},on:{"update:path":function($event){_vm.path=$event}}}):_vm._e(),((_vm.markers != null && _vm.markers.length > 0))?_c('gmap-marker',{attrs:{"position":(_vm.markers != null && _vm.markers.length > 0) ? _vm.markers[0].position : 0,"clickable":true,"draggable":false,"icon":{
						url: ('http://maps.google.com/mapfiles/ms/icons/green-dot.png'),
						scaledSize: { width: 50, height: 50, f: 'px', b: 'px' },
					}}}):_vm._e(),((_vm.markers != null && _vm.markers.length > 0))?_c('gmap-marker',{attrs:{"position":(_vm.markers != null && _vm.markers.length > 0) ? _vm.markers[_vm.markers.length - 1].position : 0,"clickable":false,"draggable":false,"icon":{
						url: ('http://maps.google.com/mapfiles/ms/icons/red-dot.png'),
						scaledSize: { width: 50, height: 50, f: 'px', b: 'px' },
					}}}):_vm._e(),_vm._l((_vm.markersSw),function(item){return _c('span',{key:item._id},[(_vm.showSw)?_c('gmap-marker',{attrs:{"position":item.position,"clickable":false,"draggable":false,"icon":{
								url: ('http://maps.google.com/mapfiles/ms/icons/yellow-dot.png'),
								scaledSize: { width: 50, height: 50, f: 'px', b: 'px' },
							}}}):_vm._e()],1)})],2)],1),_c('v-card-actions',[_c('div',{staticClass:"d-flex mx-auto align-center"},[_c('v-menu',{ref:"menu1",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
							var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"label":"Starting date","readonly":""},model:{value:(_vm.startingDateFormatted),callback:function ($$v) {_vm.startingDateFormatted=$$v},expression:"startingDateFormatted"}},on))]}}],null,false,3581647391),model:{value:(_vm.menu1),callback:function ($$v) {_vm.menu1=$$v},expression:"menu1"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":"","max":_vm.today},on:{"input":function($event){_vm.menu1 = false}},model:{value:(_vm.startingDate),callback:function ($$v) {_vm.startingDate=$$v},expression:"startingDate"}},[_c('v-spacer')],1)],1),_c('v-menu',{ref:"menu1",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
							var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"label":"Ending date","readonly":""},model:{value:(_vm.endingDateFormatted),callback:function ($$v) {_vm.endingDateFormatted=$$v},expression:"endingDateFormatted"}},on))]}}],null,false,1634389055),model:{value:(_vm.menu2),callback:function ($$v) {_vm.menu2=$$v},expression:"menu2"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":"","max":_vm.today},on:{"input":function($event){_vm.menu2 = false}},model:{value:(_vm.endingDate),callback:function ($$v) {_vm.endingDate=$$v},expression:"endingDate"}},[_c('v-spacer')],1)],1),_c('v-btn',{staticClass:"ml-2",attrs:{"small":""},on:{"click":_vm.refreshTracking}},[_vm._v("RELOAD")]),_c('v-btn',{staticClass:"ml-2",attrs:{"outlined":"","color":"accent","small":""},on:{"click":function($event){_vm.showSw = !_vm.showSw}}},[_vm._v(_vm._s(_vm.showSw ? 'Hide' : 'Show')+" SW")])],1)])],1):_vm._e(),(_vm.filterTracking.length > 0)?_c('show-gmap-log',{key:_vm.logKey,attrs:{"positions":_vm.filterTracking}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }