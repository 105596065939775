<template>
     <v-container fluid class="accent">
      <v-row justify="center">
      <v-col cols="12" sm="10">
        <v-data-table
          dark
          class="secondary elevation-4"
          :search="search"
          :headers="headers"
          :items="dealers"
          no-data-text="Loading Dealers ..."
          :loading="!loaded"
          v-cloak
          :page.sync="page"
          :items-per-page="itemsPerPage"
          hide-default-footer
          @page-count="pageCount = $event"
          @pagination="filteredDealers"
        >
          <!-- slot Top table -->
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>Dealers List</v-toolbar-title>
              <div class="ml-2">({{ dealers.length }})</div>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-spacer />
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
              ></v-text-field>
            <template >
              <v-col align="right" justify="center" class="my-3">
                <DialogDealer />
                <!-- <v-btn class="mt-2" fab dark color="secondary" @click.stop="newDealer">
                  <v-icon dark>mdi-plus</v-icon>
                </v-btn> -->
              </v-col>
            </template>
            </v-toolbar>
          </template>
          <!-- Status -->
          <template v-slot:item.active="{ item }">
            <v-switch
                color="success"
                v-model="item.active"
                @change="toogleActive(item)"
            ></v-switch>
          </template>
          <!-- columna accion -->
          <template v-slot:item.action="{ item }">
            <!-- <v-icon color="admin_info"  class="mr-2">
              mdi-format-list-bulleted
            </v-icon> -->
            <v-icon color="admin_info " @click="goSite(item)" class="mr-2 iconos">
              mdi-eye
            </v-icon>

            <v-icon
              @click="editDealer(item)"
              color="admin_info" class="mr-2">
              mdi-square-edit-outline
            </v-icon>
            <!-- <v-icon color="admin_info"  class="mr-2">
            mdi-sack-percent
              @click="deleteDealer(item, dealers.map(function(x) {return x.id; }).indexOf(item.id))"
            </v-icon> -->
             <v-icon
              @click="deleteDealer(item)"
            >
              mdi-delete-forever-outline
            </v-icon>
          </template>
        </v-data-table>
        <!-- Pagination -->
        <div class="text-center pt-2">

          <v-pagination v-model="page" :length="pageCount" circle></v-pagination>
          <div class="grey--text ">Shows {{itemsTotal}} records</div>

        </div>

        <download-excel :data="dealers" type="csv" :fields="json_fields">
            <v-icon class="pointer" large color="grey lighten-1"> mdi-download</v-icon> <span class="grey--text lighten-1">Download CSV</span >
          </download-excel>

      </v-col>
    </v-row>
    <!-- <Notification /> -->
    <v-snackbar
      top
      right
      :timeout="alert.timeout"
      :color="alert.color"
      v-model="alert.show">
        {{ alert.message }}
    </v-snackbar>
  </v-container>
</template>

<script>
  import { mapState, mapActions, mapGetters} from 'vuex';
  import { dbFt,storage } from '@/main';
  export default {
    components: {
      DialogDealer: () => import('@/components/admin/DialogDealer.vue'),
    },
    created(){
      this.$store.commit('SET_LOADED', false,{root: true});
      this.getDealers();
    },
   data: () => ({
    page: 1,
    pageCount: 0,
    itemsPerPage: 20,
    itemsTotal: 0,
    loading: false,
     search: '',
      headers: [
        {text: 'Name', value: 'name', align: 'left'},
        {text: 'Phone', value: 'phone', align: 'left'},
        {text: 'Mail', value: 'email', align: 'left'},
        {text: 'Active', value: 'active', align: 'left'},
        {text: '', value: 'action', sortable: false,align: 'end', },
      ],
    }),
    methods: {
      ...mapActions('dealerModule',['getDealers','toggleDealerDialog']),
      newDealer(){
        this.toggleDealerDialog({
          editMode: false,
          dealer: {},
        });
      },
      editDealer(item){
        this.toggleDealerDialog({
          editMode: true,
          dealer: item,
        });
      },
      async deleteDealer(item){
        let res = await this.$confirm('Do you really want to remove this Dealer? This record will be permanently delete',
            {
              title: "WARNING",
              buttonTrueText: "Accept",
              buttonFalseText: "Discard",
              color: "red",
              icon: "mdi-hand-right",
              width: 350,
              property: "$confirm",
            })
        if (res) {
          await dbFt.collection('dealers').doc(item.id).delete();
            this.$store.commit('SET_ALERT_MESSAGE', {
              show: true,
              color: 'error',
              message: 'Dealer deleted successful',
              timeout: 3000
            });

           await storage.ref(`dealers/${item.logo_name}`).delete()
             .then(function() {}).catch(function(error) {});

        }

      },
      toogleActive(item){
        dbFt.collection('dealers').doc(item.id).update({ active: item.active });
      },
      goSite(item){
          window.open("https://dealersite.locmarine.com/?dealer="+item.id, "_blank");
      },
      filteredDealers(pagination){
        this.itemsTotal = pagination.itemsLength
      }
    },
    computed: {
      ...mapState(['loaded','alert']),
      ...mapState('dealerModule',['dealers','dialog']),
      ...mapGetters('dealerModule',['getterDealer','getterDealers']),
    }
  }
</script>