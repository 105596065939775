var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"accent",attrs:{"fluid":""}},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"secondary elevation-4",attrs:{"fixed-header":"","height":"75vh","dark":"","loading":_vm.loading,"search":_vm.search,"headers":_vm.headers,"items":_vm.devicesDetail,"no-data-text":"Loading Devices ...","items-per-page":10,"footer-props":{
        'items-per-page-options': [50, 100, 200, -1]
        }},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),[_c('v-col',{staticClass:"my-3",attrs:{"align":"right","justify":"center"}},[_vm._v(" TOTAL: "+_vm._s(_vm.devicesDetail.length)+" ")])]],2)]},proxy:true},{key:"item.imei",fn:function(ref){
        var item = ref.item;
return [_c('div',{staticClass:"pointer text-subtitle-1",domProps:{"innerHTML":_vm._s(item.imei)},on:{"click":function($event){return _vm.showInputs(item)}}})]}},{key:"item.boatname",fn:function(ref){
        var item = ref.item;
return [_c('span',{staticClass:"pointer",domProps:{"innerHTML":_vm._s(item.boatname)},on:{"click":function($event){return _vm.showInputs(item)}}})]}},{key:"item.firstname",fn:function(ref){
        var item = ref.item;
return [_c('span',{staticClass:"pointer",domProps:{"innerHTML":_vm._s(item.firstname)},on:{"click":function($event){return _vm.showInputs(item)}}})]}},{key:"item.expiration",fn:function(ref){
        var item = ref.item;
return [_c('div',{staticClass:"pointer text-center",on:{"click":function($event){$event.preventDefault();return _vm.editExpiration(item)}}},[_vm._v(_vm._s(item.expiration != null ? _vm.formatDate(item.expiration) : '______'))])]}},{key:"item.notes",fn:function(ref){
        var item = ref.item;
return [_c('div',{staticClass:"pointer",on:{"click":function($event){$event.preventDefault();return _vm.editNotes(item)}}},[_vm._v(_vm._s(item.notes))])]}},{key:"item.billing",fn:function(ref){
        var item = ref.item;
return [_c('v-switch',{attrs:{"color":"success"},on:{"change":function($event){return _vm.toogleBilling(item)}},model:{value:(item.billing),callback:function ($$v) {_vm.$set(item, "billing", $$v)},expression:"item.billing"}})]}},{key:"item.installer",fn:function(ref){
        var item = ref.item;
return [_c('v-switch',{attrs:{"color":"success"},on:{"change":function($event){return _vm.toogleInstaller(item)}},model:{value:(item.installer),callback:function ($$v) {_vm.$set(item, "installer", $$v)},expression:"item.installer"}})]}},{key:"item.action",fn:function(ref){
        var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2 d-none d-sm-flex",staticStyle:{"font-size":"60px"},attrs:{"color":"grey lighten-3"},on:{"click":function($event){return _vm.showInputs(item)}}},[_vm._v(" mdi-chevron-double-right ")])]}}])}),_c('download-excel',{attrs:{"data":_vm.devicesDetail,"type":"csv","fields":_vm.json_fields}},[_c('v-icon',{staticClass:"pointer",attrs:{"large":"","color":"grey lighten-1"}},[_vm._v(" mdi-download")]),_vm._v(" "),_c('span',{staticClass:"grey--text lighten-1"},[_vm._v("Download CSV")])],1)],1)],1),_c('QrDialog'),_c('AssignDialog'),_c('DialogInfoBoat'),_c('v-dialog',{attrs:{"max-width":"350"},model:{value:(_vm.dialogField),callback:function ($$v) {_vm.dialogField=$$v},expression:"dialogField"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline text-uppercase"},[_vm._v(" "+_vm._s(_vm.fieldEdit)+" ")]),(_vm.fieldEdit == 'expiration')?_c('v-card-text',[_c('v-text-field',{attrs:{"hint":"Use mm/dd/yyyy format"},model:{value:(_vm.expirationField),callback:function ($$v) {_vm.expirationField=$$v},expression:"expirationField"}})],1):_vm._e(),(_vm.fieldEdit == 'notes')?_c('v-card-text',[_c('v-textarea',{attrs:{"outlined":"","value":_vm.notesField,"rows":"2"},model:{value:(_vm.notesField),callback:function ($$v) {_vm.notesField=$$v},expression:"notesField"}})],1):_vm._e(),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"red","text":""},on:{"click":function($event){_vm.dialogField = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"loading":_vm.saving,"color":"secondary"},on:{"click":function($event){return _vm.saveField()}}},[_vm._v(" SAVE ")])],1)],1)],1),_c('v-snackbar',{attrs:{"top":"","center":"","timeout":_vm.alert.timeout,"color":_vm.alert.color},model:{value:(_vm.alert.show),callback:function ($$v) {_vm.$set(_vm.alert, "show", $$v)},expression:"alert.show"}},[_vm._v(" "+_vm._s(_vm.alert.message)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }