<template>

<v-app>
   <router-view/>
</v-app>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  name: 'App',

  components: {

  },

  data: () => ({
    //
  }),
};
</script>
