<template>
  <v-container fluid class="accent">
    <v-row justify="center">
      <v-col cols="12">

        <v-data-table fixed-header height="75vh" dark class="secondary elevation-4" :loading="loading" :search="search" :headers="headers"
          :items="devicesDetail" no-data-text="Loading Devices ..." :items-per-page="10"

          :footer-props="{
          'items-per-page-options': [50, 100, 200, -1]
          }">
          <!-- v-cloak
          :page.sync="page"
          :items-per-page="itemsPerPage"
          hide-default-footer
          @page-count="pageCount = $event"
          @pagination="filteredDevices" -->

          <!-- slot Top table -->
          <template v-slot:top>
            <v-toolbar flat>
              <!-- <v-toolbar-title>Devices Admin</v-toolbar-title> -->
              <!-- <v-divider class="mx-4" inset vertical></v-divider> -->

              <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details />

              <template>
                <v-col align="right" justify="center" class="my-3">
                  TOTAL: {{ devicesDetail.length }}
                  <!-- <v-btn class="mt-2" fab dark color="secondary" v-on="on">
                      <v-icon dark>mdi-plus</v-icon>
                    </v-btn> -->
                </v-col>
              </template>
            </v-toolbar>
          </template>

          <!-- IMEI -->
          <template v-slot:item.imei="{ item }">
            <div class="pointer text-subtitle-1" v-html="item.imei" @click="showInputs(item)" />
          </template>

          <!-- Customer -->
          <template v-slot:item.boatname="{ item }">
            <span class="pointer" v-html="item.boatname" @click="showInputs(item)" />
          </template>
          <!-- Customer -->
          <template v-slot:item.firstname="{ item }">
            <span class="pointer" v-html="item.firstname" @click="showInputs(item)" />
          </template>

          <!-- Expiration -->
          <template v-slot:item.expiration="{ item }">
            <div class="pointer text-center" @click.prevent="editExpiration(item)">{{ item.expiration != null ?
            formatDate(item.expiration) : '______' }}</div>
          </template>

          <!-- Notes -->
          <template v-slot:item.notes="{ item }">
            <div class="pointer" @click.prevent="editNotes(item)">{{ item.notes }}</div>
          </template>


          <!-- Billing -->
          <template v-slot:item.billing="{ item }">
            <v-switch color="success" v-model="item.billing" @change="toogleBilling(item)" />
          </template>

          <!-- Installer Hub -->
          <template v-slot:item.installer="{ item }">
            <v-switch color="success" v-model="item.installer" @change="toogleInstaller(item)" />
          </template>


          <!-- Snooze Status -->
          <!-- <template v-slot:item.snooze="{ item }"> -->

            <!-- <v-chip class="ma-2" :color="item.snooze ? 'green darken-3' : 'red darken-3'" text-color="white">
              {{ item.snooze ? 'Y' : 'N' }}
            </v-chip> -->

            <!-- <v-switch color="success" v-model="item.active" @change="toogleActive(item)" /> -->

            <!-- <v-chip class="ma-2" :color="item.active ? 'green darken-3' : 'red darken-3'" text-color="white">
              {{ item.active ? 'ACTIVE' : 'DISABLED' }}
            </v-chip> -->


          <!-- </template> -->
          <!-- columna accion -->
          <template v-slot:item.action="{ item }" >
              <v-icon color="grey lighten-3" class="mr-2 d-none d-sm-flex" @click="showInputs(item)" style="font-size: 60px;">
                mdi-chevron-double-right
              </v-icon>

          </template>
        </v-data-table>
        <download-excel :data="devicesDetail" type="csv" :fields="json_fields">
          <v-icon class="pointer" large color="grey lighten-1"> mdi-download</v-icon> <span class="grey--text lighten-1">Download CSV</span >
        </download-excel>
      </v-col>
    </v-row>
    <QrDialog />
    <AssignDialog />
    <DialogInfoBoat />

    <!-- Dialog -->
    <v-dialog v-model="dialogField" max-width="350">
      <v-card>
        <v-card-title class="headline text-uppercase">
          {{ fieldEdit }}
        </v-card-title>

        <v-card-text v-if="fieldEdit == 'expiration'">
          <v-text-field v-model="expirationField" hint="Use mm/dd/yyyy format" />
        </v-card-text>
        <v-card-text v-if="fieldEdit == 'notes'">
          <v-textarea outlined v-model="notesField" :value="notesField" rows="2" />
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="red" text @click="dialogField = false">
            Cancel
          </v-btn>

          <v-btn :loading="saving" color="secondary" @click="saveField()">
            SAVE
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- <Notification /> -->
    <v-snackbar top center :timeout="alert.timeout" :color="alert.color" v-model="alert.show">
      {{ alert.message }}
    </v-snackbar>
  </v-container>
</template>

<script>
  import moment from 'moment';
  import { mapState, mapActions, mapGetters} from 'vuex';
  import { dbFt,dbFb } from '@/main';
  import formatMixin from '@/mixins/formatMixin'
  export default {
    name: 'AdminDeviceView',
    components: {
      QrDialog: () => import('@/components/admin/ReadQrDialog.vue'),
      AssignDialog: () => import('@/components/admin/AssaignDealer.vue'),
      DialogInfoBoat: () => import('@/components/admin/DialogInfoBoat.vue'),
    },
    created(){
      //this.$store.commit('SET_LOADED', false,{root: true});
      //this.getDevices();
    },
    mounted(){
      this.getFullDevices();
    },
    data: () => ({
      page: 1,
      pageCount: 0,
      itemsPerPage: 15,
      itemsTotal: 0,
      loading: false,
      saving: false,
      dialogField: false,
      devicesDetail: [],
      expirationField: '',
      notesField: '',
      fieldEdit: '',
      imei: '',
      index: 0,
      json_fields: {
      'FIRST NAME': 'firstname',
      'LAST NAME': 'lastname',
      'EMAIL': 'email',
    },
      json_fields2: {
       'IMEI': 'imei',
       'SERIAL NUMBER': 'sn',
       'DEALER NAME': 'dealer_name',
       'DISTRIBUITOR NAME': 'distribuitor_name',
       'BOAT NAME': 'boatname',
       'FIRST NAME': 'firstname',
       'LAST NAME': 'lastname',
       'PHONE': 'phone',
       'EMAIL': 'email',
       'EXPIRATION': {
					field: 'expiration',
					callback: (value) => {
						return value != 'NOT SET' && value != '' ? moment(value).format('MM/DD/YYYY') : '';
					}
				},
       'PRIVATE NOTES': 'notes',
       'BILLING': 'billing',
       'SNOOZE': 'snooze',

      },
      search: '',
      headers: [
        {text: 'IMEI', value: 'imei', align: 'left'},
        { text: 'SERIAL', value: 'sn', sortable: false, align: 'left'},
        {text: 'HUB', value: 'installer', align: 'left', width: '5%'},
        {text: 'DEALER', value: 'dealer_name', align: 'left'},
        // {text: 'DISTRIBUITOR', value: 'distribuitor_name', align: 'left'},
        {text: 'BOAT', value: 'boatname', align: 'left'},
        {text: 'CUSTOMER', value: 'firstname', align: 'left'},

        {text: 'EXPIRATION', value: 'expiration', align: 'left', },
        { text: 'NOTES', sortable: false, value: 'notes', align: 'left'},
        { text: 'BILLING', value: 'billing', sortable: false, align: 'left', },
        // {text: 'SNOOZE', value: 'snooze', align: 'left',},
        {text: '', value: 'action', sortable: false,align: 'end', },
      ],
      alert: {
        timeout: 2000,
        color: 'successful',
        show: false,
        message: ''
      },

    }),
    methods: {
      ...mapActions('deviceModule',[
				'getDevices','toggleDeviceDialog','toggleInputDeviceDialog','setDevice','setInfo',
			]),
      ...mapActions('dealerModule',['getDealers', 'getdealer']),

      async getFullDevices() {
				let me = this
        me.loading = true

        await dbFt.collection("devices").get().then(function (querySnapshot) {
          try {
            querySnapshot.forEach((doc) => {
							let device = doc.data();
              if (device.installer == null ){
                device.installer = false
              }

              let info = {}

                me.devicesDetail.push({
                  imei: device.imei,
                  sn: device.sn,
                  dealer_id: device.dealer_id,
                  dealer_name: device.dealer_name,
                  distribuitor_id: device.distribuitor_id,
                  distribuitor_name: device.distribuitor_name,
                  expiration: device.expiration,
                  notes: device.notes,
                  billing: device.billing == null ? false : device.billing,
                  active: device.active,
                  installer: device.installer,
                  boatname:  '',
                  firstname:  '',
                  lastname:  '',
                  email:  '',
                  phone:  '',
                  log:  '',
                  snooze: false
                })
            });
          } catch(e){
            console.log(e)
          } finally {
            me.loading = false
          }
        });

        me.devicesDetail.sort((a, b) => Number(b.billing) - Number(a.billing))

        me.devicesDetail.forEach( (x,i) => {
          let info ={}
          dbFb.ref(`/${x.imei}/info`).once('value')
            .then(function (snap) {
                info = snap.val();
                if (info != null) {
                  info.log = info.log == null ? false : info.log
                  info.code = String(info.code)
                  // const firstname = info?.firstname ?? 'CUSTOMER'
                  const firstname = `
                  <div>${info?.firstname ?? 'CUSTOMER <br/>NOT SET'} ${info.lastname ?? ''}</div>
                  <div>${info?.phone ? info.phone : 'PHONE NOT SET'}</div>
                  <div>${info?.email ? info.email : ''}</div>
                `
                  me.devicesDetail[i].boatname = info?.boatname ?? 'NOT SET'
                  me.devicesDetail[i].lastname = info?.lastname ?? ''
                  me.devicesDetail[i].firstname = firstname
                  me.devicesDetail[i].email = info?.email ?? ''
                  me.devicesDetail[i].phone = info?.phone ?? ''
                  me.devicesDetail[i].log = info.log
                  me.devicesDetail[i].log = info.code
                }
            })
          /* let data = {}
          dbFb.ref(`/${x.imei}/data`).once('value')
            .then(function (snap) {
              data = snap.val();
              if (data != null) {
                me.devicesDetail[i].snooze = data.snooze ? data.snooze : false
              }
            }) */
        })
      },

      toogleActive(item){
        dbFt.collection('devices').doc(item.imei).update({ active: item.active });
      },

      toogleInstaller(item){
        dbFt.collection('devices').doc(item.imei).update({ installer: item.installer });
      },

       toogleBilling(item){
         dbFt.collection('devices').doc(item.imei).update({ billing: item.billing })
      },

      showInputs(item){

        this.setDevice(item);

        this.$router.push({
          name: "AdminShowInputs",
          params: { imei: item.imei }
        });
      },

      formatDate(date){
        return formatMixin.formatDate(date)
      },
      formatDateDefault(date) {
        return formatMixin.formatDateDefault(date)
      },

      filteredDevices(pagination){
        this.itemsTotal = pagination.itemsLength
      },

      editExpiration(item){
        this.imei = item.imei
        this.index = this.devicesDetail.map(e => e.imei).indexOf(item.imei);
        this.expirationField = this.formatDate(this.devicesDetail[this.index].expiration)

        this.fieldEdit = 'expiration'
        this.dialogField = true
      },
      editNotes(item){
        this.imei = item.imei
        this.index = this.devicesDetail.map(e => e.imei).indexOf(item.imei);
        this.notesField = this.devicesDetail[this.index].notes
        this.fieldEdit = 'notes'
        this.dialogField = true
      },
      async saveField() {
        let me = this
        let ref = dbFt.collection('devices').doc(me.imei)
        let valueInput = ''
        me.saving = true

        if (this.fieldEdit == 'expiration'){
          valueInput = this.formatDateDefault(this.expirationField)
          this.devicesDetail[me.index].expiration = valueInput
        } else {
          this.devicesDetail[me.index].notes = this.notesField
          valueInput = this.notesField
        }

        let data = { [me.fieldEdit]: valueInput, }
        await dbFt.collection('devices').doc(me.imei).update(data)
          .then(docRef => {
            me.alert = {
              show: true,
              color: 'success',
              message: `${this.fieldEdit} was successfully updated`,
            }
            setTimeout(() => {
              me.alert.show = false
              me.dialogField = false
              me.saving = false
            }, 1300);
          })
          .catch(function (error) {
            console.log("Error :", error);
            me.dialogField = false
            me.saving = false
          });
      }

    },
    computed: {
      ...mapState(['loaded']),
      ...mapState('deviceModule',['devices','dialog']),
      ...mapGetters('deviceModule',['getterDevice','getterDevices']),
    }

  }
</script>



<style>
.pointer {
  cursor: pointer;
}

.v-data-table {
  line-height: 1.0 !important;
}

.v-data-table>.v-data-table__wrapper .v-data-table__mobile-row {
  min-height: 28px !important;
}

.v-data-table>.v-data-table__wrapper>table>tbody>tr>td,
.v-data-table>.v-data-table__wrapper>table>tbody>tr>th,
.v-data-table>.v-data-table__wrapper>table>thead>tr>td,
.v-data-table>.v-data-table__wrapper>table>thead>tr>th,
.v-data-table>.v-data-table__wrapper>table>tfoot>tr>td,
.v-data-table>.v-data-table__wrapper>table>tfoot>tr>th {
  padding: 0 12px !important;
}

@media only screen and (max-width: 600px) {
  .v-data-table-header.v-data-table-header-mobile {
    display: none;
  }
}
</style>
