<template>
	<v-container fluid fill-height style="background: #3286C5">
		<v-layout flex flex-column align-center justify-center>
			<v-card v-if="notFound" max-width="400" max-height="400">
				<v-img src="/img/not-found.png" />
			</v-card>
			<v-card
				:loading="!loaded"
					class="mx-auto"
					max-width="400"
					outlined
					v-else
				>
					<div class="text-center">
						<img :src="dealer.logo_url" width="300"/>
					</div>
					<v-card-title v-html="dealer.name"></v-card-title>
					<v-card-text>
						<div>
							{{ dealer.address}}	
						</div>
						<div>
							{{ dealer.phone}}
						</div>
						<div>
							{{ dealer.email}}
						</div>
						<div>
							{{ dealer.website}}	
						</div>
					</v-card-text>
					<v-divider class="mx-4"></v-divider>

					<v-card-actions>
						
						<v-spacer></v-spacer>
						<v-btn icon :href="`tel: ${dealer.phone}`">
							<v-icon class="ma-4">mdi-cellphone-basic</v-icon>
						</v-btn>
						<v-btn icon :href="`mailto: ${dealer.email}`" target="_blank">
							<v-icon class="ma-4">mdi-email</v-icon>
						</v-btn>			
						<v-btn icon :href="dealer.website" target="_blank">
							<v-icon class="ma-4">mdi-web</v-icon>
						</v-btn>
					</v-card-actions>
				</v-card>				
		</v-layout>
 </v-container>
</template>
<script>
	import { mapActions, mapState } from "vuex";
  export default {
    data: () => ({
    }),
		created(){
			this.$store.commit('SET_LOADED', false, {root: true})
			this.getDealer(this.$route.params.id);
		},
    methods: {
			...mapActions('dealerModule',['getDealer']),
    },
		computed: {
		...mapState(['loaded']),	
		...mapState('dealerModule',['dealer']),
		notFound(){
			return this.dealer.id === null && this.loaded;
		}
		}	
  }
</script>
