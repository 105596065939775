<template>
  <v-app id="inspire" >
    <Toolbar v-if="!hiddenToolbar"/>
    <v-main class="blue-grey darken-3">

      <board-principal v-if="isDashbaord" />

      <transition name="fade" mode="out-in">
        <router-view/>
      </transition>
    </v-main>
    <v-footer app v-if="!hiddenToolbar" class="blue-grey darken-4 white--text">
      <span>&copy; Locmarine Telematic 2020 - {{ new Date().getFullYear() }}. v.1.2.0</span>
    </v-footer>
    <!-- <Notification /> -->
    <v-snackbar
      top
      right
      :timeout="alert.timeout"
      :color="alert.color"
      v-model="alert.show">
        {{ alert.message }}
    </v-snackbar>
  </v-app>
</template>

<script>
  import { mapState } from 'vuex';
  export default {
  name: 'AdminDashboardView',
    components: {
      Toolbar: () => import('@/components/admin/Toolbar'),
      BoardPrincipal: () => import('@/components/dashboard/BoardPrincipal.vue'),
    },
    props: {
      source: String,
    },
    data: () => ({

    }),
    created () {
      // console.log(this.$route.name)
      // this.$vuetify.theme.dark = false
    },
		mounted(){
			//console.log(this.hiddenToolbar)
		},
    computed: {
      ...mapState(['loaded','alert']),
      isDashbaord() {
        return this.$route.name === 'AdminDashboard';
      },
			hiddenToolbar(){
				return this.$route.name == 'PrintLabelQr' || this.$route.name == 'PrinterLabelSerial'
			}
    }
  }
</script>

<style scoped>
  .fade-enter-active,
  .fade-leave-active {
    opacity: 1;
    transition: all 0.5s ease-in-out;
  }
  .fade-enter,
  .fade-leave {
    opacity: 0;
}
</style>