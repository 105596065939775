<template>
	<div>
		<v-container fluid class="accent py-0 mt-0" v-if="loaded">
			<!-- Breadcrumb -->
      <v-row class="mb-2 pt-4 secondary">
        <v-col cols="12" color="blue-grey" class="px-5 white--text text-center">
          <v-icon @click="goDevice()" class="mr-2 white--text" large >mdi-chevron-double-left</v-icon>
          <span class="font-weight-bold">IMEI: </span>{{ imei }} |
          {{ timestamp | moment('MM/DD/YYYY, H:mm:ss') }} |
          <span class="font-weight-bold">Last update:</span> {{ timestamp | moment('from', 'now', true) }}
        </v-col>
				<v-col cols="12">
					<show-gmap :imei="imei" :isLog="true" />
				</v-col>
      </v-row>
		</v-container>
		<v-container class="mb-12 pb-12 accent" v-if="!loaded">
				<v-layout flex align-center justify-center>
					<v-progress-circular indeterminate :size="250" :width="20" color="secondary" />

				</v-layout>
			</v-container>
	</div>
</template>

<script>
	import { dbFb } from '@/main';
import ShowGmap from '@/components/admin/ShowGmap.vue';
	export default {
		name: 'AdminTracking',
  	components: { ShowGmap },
		data() {
			return {
				loaded: false,
				timestamp: 0
			}
		},
		mounted(){
			this.getData()
		},
		methods: {
			getData(){
				this.loaded = false
				dbFb.ref(`/${this.imei}/data`).on("value", (snapshot) => {
					this.timestamp = snapshot.val().timestamp
				});
				this.loaded = true
			},
			goDevice(){
        this.$router.push({
          name: "AdminShowInputs",
          params: { imei: this.imei }
        });
      },
		},
		computed: {
			imei(){
				return this.$route.params.imei
			}
		}

	}
</script>

<style lang="scss" scoped>

</style>



