<template>
	<v-container fluid class="py-0 mt-0">
		<v-row style="background: #37474F" class="mb-5">
			<v-col cols="12" color="blue-grey" class="px-5 white--text text-center">
				<v-icon @click="goBack()" class="mr-2 white--text" large>mdi-chevron-double-left</v-icon>
				<span class="font-weight-bold">IMEI: </span>{{ imei }}
			</v-col>
		</v-row>
		<v-row justify="center">
			<v-col cols="12" sm="7">
				<v-card dark outlined elevation="5">
					<v-card-title class="mb-4 secondary white--text">EDIT INPUTS</v-card-title>
					<v-card-text>
						<v-list>
							<div v-for="(item,key) in items" :key="key">
								<v-card outlined elevation="5" class="mb-3 pa-3">
									<v-card-title class="primary white--text">
										io{{ getId(item) }}
									</v-card-title>
									<v-list-item @click="edit({ id: getId(item), key: item[it-1].key, value: item[it-1].value })"
										v-for="(it,k2) in item.length" :key="k2" :class="k2 % 2 === 0 ? 'grey darken-2' : ''">
										<v-list-item-content>
											<h3 class="font-weight-black">{{ item[it-1].key }}</h3>
										</v-list-item-content>
										<v-list-item-action>
											<span :ref="getId(item) +item[it-1].key" :id="item[4].value+item[it-1].key"
												class="font-weight-medium">
												{{ item[it-1].value }}
											</span>
										</v-list-item-action>

									</v-list-item>
								</v-card>
							</div>
						</v-list>
					</v-card-text>
					<v-card-actions>
						<v-btn text @click="goBack()" class="red--text mr-2">
							<v-icon class="mr-2">mdi-chevron-double-left</v-icon> GO BACK
						</v-btn>
						<v-spacer />
						<!-- <v-btn x-large class="primary" @click.stop="saveInput">
							<v-icon class="mr-2">mdi-database-check</v-icon> SAVE INPUTS
						</v-btn> -->
					</v-card-actions>
				</v-card>

			</v-col>
		</v-row>
		<!-- <Notification /> -->
		<v-snackbar top center :timeout="alert.timeout" :color="alert.color" v-model="alert.show">
			{{ alert.message }}
		</v-snackbar>
		<!-- Dialog -->
		<v-dialog v-model="dialog" max-width="350">
			<v-card>
				<v-card-title class="headline">
					INPUT UPDATE
				</v-card-title>

				<v-card-text>
					IMEI: {{ imei }}<br />
					io: {{ io }}<br />
					KEY: {{ ikey }}<br />
					<v-text-field v-model="ivalue" />
					<v-select v-model="itype" :items="['Boolean','Number','String']" label="Tipo de Dato" dense solo />

				</v-card-text>

				<v-card-actions>
					<v-spacer></v-spacer>

					<v-btn color="red" text @click="dialog = false">
						Cancel
					</v-btn>

					<v-btn color="secondary" @click="saveInput">
						SAVE
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>


	</v-container>
</template>

<script>
	import { dbFb } from '@/main';
	export default {
		name: 'EditInputs',
		data: ()=>({
			ref: '',
			io: '',
			ikey: '',
			ivalue: '',
			itype: '',
			dialog: false,
			values: {},
			alert: {
				timeout: 4000,
				color: 'successful',
				show: false,
				message: ''
			},
			jsonFields: [],
			items: []
		}),
		mounted(){
			//console.log(this.$route.params)

			this.getData()

		},
		methods: {
			getData(){
				var res = []
				var output = null
				Object.entries(this.inputs).forEach(([key, value]) => {
					output = Object.entries(value).map(([key, value]) => ({key,value}));
					res.push(output)
				});

				for (let index = 0; index < res.length; index++) {

					const element = res[index];

					const subElement = []
					for (let index = 0; index < element.length; index++) {
							subElement.push(element[index]);
							// console.log(el.key +' '+ el.value)
							// console.log(subElement)
					}
					this.jsonFields.push(subElement)
				}

				for (let index = 0; index < this.jsonFields.length; index++) {
					this.items.push(this.jsonFields[index])
				}
			},
			edit(item){

				this.ref=`${item.id}${item.key}`
				this.io = `io${item.id}`
				this.ikey = item.key
				this.ivalue = this.$refs[this.ref][0].innerText
				//this.ivalue = item.value
				this.dialog=true
			},
			goBack() {
				let me=this
				this.$router.push({
          name: "AdminShowInputs",
          params: { imei: me.imei }
        });
			},

			getId(item){

				const res = item.find(e => e.key === "id")

				return res?.value

			},

			async saveInput(){

				let me=this
				// const iKey = me.ikey
				// const iValue = me.ivalue

				let iValue = null

				switch (me.itype) {
					case 'Boolean':
						iValue = (me.ivalue.toLowerCase() == 'true')
						break
					case 'Number':
						iValue = parseInt(me.ivalue)
						break
					case 'String':
						iValue = me.ivalue
						break
					default:
						iValue = me.ivalue
						break
				}

				let data = {
						[me.ikey]: iValue,
				}

				await dbFb.ref(`/${me.imei}/idata/${me.io}`).update(data)
				.then( docRef => {

						me.$refs[this.ref][0].innerText = me.ivalue
						me.ivalue = ''

           me.alert ={
							show: true,
							color: 'success',
							message: `${this.ikey} was successfully updated`,
						}
            setTimeout(() => {
							me.alert.show = false
							me.dialog = false
						}, 1300);
          })
				.catch(function(error) {
					console.log("Error :", error);
					me.dialog = false
				});

			},
		},
		computed: {
      imei(){
				return this.$route.params.imei;
      },
			inputs: {
				get(){
					return this.$route.params.inputs
				},
				set(value){
					this.values = value
				}
			}
    }
	}
</script>

<style lang="scss" scoped>

</style>