<template>
	<v-sheet class="accent">
		<div v-if="loading">
			<v-container class="mb-12 pb-12 accent">
				<v-layout flex align-center justify-center column>
					<v-progress-circular indeterminate :size="250" :width="20" color="primary" />
					<div class="primary--text">Loading...</div>
				</v-layout>
			</v-container>
		</div>
		<v-card dark color="secondary" elevation="0" v-if="!loading">
			<v-card-text>
				<GmapMap ref="loc" :center="center" :zoom="12" style="width: 100%; height: 600px" :options="{
					zoomControl: true,
					mapTypeControl: true,
					mapTypeId: 'hybrid',
					scaleControl: true,
					streetViewControl: false,
					rotateControl: false,
					fullscreenControl: true,
					disableDefaultUi: false,
				}">

					<GmapPolyline v-if="path.length > 0" v-bind:path.sync="path" v-bind:options="{
						strokeColor: '#FF0000',
						icons: [{ icon: { path: lineSymbol, scale: 2, }, offset: '0', repeat: '70px' }]
					}" />
					<gmap-marker v-if="(markers != null && markers.length > 0)"
						:position="(markers != null && markers.length > 0) ? markers[0].position : 0" :clickable="true"
						:draggable="false" :icon="{
							url: ('http://maps.google.com/mapfiles/ms/icons/green-dot.png'),
							scaledSize: { width: 50, height: 50, f: 'px', b: 'px' },
						}" />
					<gmap-marker v-if="(markers != null && markers.length > 0)"
						:position="(markers != null && markers.length > 0) ? markers[markers.length - 1].position : 0"
						:clickable="false" :draggable="false" :icon="{
							url: ('http://maps.google.com/mapfiles/ms/icons/red-dot.png'),
							scaledSize: { width: 50, height: 50, f: 'px', b: 'px' },
						}" />

						<span v-for="item in markersSw" :key="item._id">
							<gmap-marker
								v-if="showSw"
								:position="item.position"
								:clickable="false"
								:draggable="false" :icon="{
									url: ('http://maps.google.com/mapfiles/ms/icons/yellow-dot.png'),
									scaledSize: { width: 50, height: 50, f: 'px', b: 'px' },
								}"
							/>
						</span>

				</GmapMap>
			</v-card-text>
			<v-card-actions>
				<div class="d-flex mx-auto align-center">
					<v-menu ref="menu1" v-model="menu1" :close-on-content-click="false" transition="scale-transition" offset-y
						min-width="290px">
						<template v-slot:activator="{ on }">
							<v-text-field v-model="startingDateFormatted" label="Starting date" readonly v-on="on" />
						</template>
						<v-date-picker v-model="startingDate" no-title scrollable :max="today" @input="menu1 = false">
							<v-spacer></v-spacer>
						</v-date-picker>
					</v-menu>
					<v-menu ref="menu1" v-model="menu2" :close-on-content-click="false" transition="scale-transition" offset-y
						min-width="290px">
						<template v-slot:activator="{ on }">
							<v-text-field v-model="endingDateFormatted" label="Ending date" readonly v-on="on" />
						</template>
						<v-date-picker v-model="endingDate" no-title scrollable :max="today" @input="menu2 = false">
							<v-spacer></v-spacer>
						</v-date-picker>
					</v-menu>
					<v-btn class="ml-2" small @click="refreshTracking">RELOAD</v-btn>
					<v-btn class="ml-2" outlined color="accent" small @click="showSw = !showSw">{{ showSw ? 'Hide' : 'Show' }} SW</v-btn>
				</div>
			</v-card-actions>
		</v-card>

		<show-gmap-log :positions="filterTracking" :key="logKey" v-if="filterTracking.length > 0" />

	</v-sheet>
</template>

<script>
import dayjs from 'dayjs'
import axios from 'axios';
const header = { "Token": localStorage.getItem("token") }
const configuration = { headers: header }
export default {
	name: 'ShowGmap',
	props: {
		imei: String,
	},
	components: {
		MainLoaded: () => import('@/components/base/MainLoaded.vue'),
		ShowGmapLog: () => import('@/components/admin/ShowGmapLog.vue'),
	},
	created() {
		this.startingDate = dayjs().subtract(7, 'day').format('YYYY-MM-DD')
		this.getData()
	},
	data: () => ({
		logKey: 0,
		menu1: false,
		menu2: false,
		today: new Date().toISOString(),
		startingDate: new Date().toISOString(),
		endingDate: new Date().toISOString(),
		showActualPosition: true,
		pastDate: null,
		Pastdays: '8',
		showTracking: false,
		loading: false,
		center: { lat: 25.7383699, lng: -80.2021549 },
		lat: 0,
		lon: 0,
		markers: [],
		markersSw: [],
		currentMidx: null,
		showSw: false,
		path: [],
		allTracking: [],
		filterTracking: [],

	}),
	methods: {
		async getData() {
			this.loading = true
			try {
				let { data } = await axios.get(`record/query?imei=${this.imei}`, configuration);

				const dataJson = JSON.stringify(data)
				this.allTracking = JSON.parse(dataJson)
				this.filterTracking = JSON.parse(dataJson)

				this.refreshTracking()
			} catch (error) {
				console.log(error)
			} finally {
				this.loading = false
			}
		},

		formatDate(date) {
			if (!date) return null
			const [month, day, year] = date.split('-')
			return `${year}-${month}-${day}`
		},

		async refreshTracking() {
			let me = this

			const startDay   = dayjs(this.startingDate).date()
			const startMonth = dayjs(this.startingDate).month()
			const startYear  = dayjs(this.startingDate).year()

			const endDay   = dayjs(this.endingDate).date()
			const endMonth = dayjs(this.endingDate).month()
			const endYear  = dayjs(this.endingDate).year()

			var objectSupport = require("dayjs/plugin/objectSupport");
			dayjs.extend(objectSupport);
			const startt = dayjs({ year: startYear, month: startMonth, day: startDay, hour: 0, minute: 1, second: 0, millisecond: 0 })
			const start = (startt.valueOf() / 1000).toFixed(0)
			const endd = dayjs({ year: endYear, month: endMonth, day: endDay, hour: 23, minute: 59, second: 59, millisecond: 0 })
			const end = (endd.valueOf() / 1000).toFixed(0)

			this.filterTracking = await this.allTracking.filter(x => x.timestamp >= start && x.timestamp <= end)
			this.showTrackingPath()

			this.logKey = dayjs().valueOf()

			/* if (start == end) {
				this.filterTracking = await this.allTracking.filter(x => x.date == dayjs(me.startingDate).valueOf())
				this.showTrackingPath()
			}
			else if (end >= start) {
				this.filterTracking = await this.allTracking.filter(x => x.date >= start && x.date <= end)
				this.showTrackingPath()
			} */


		},


		async showTrackingPath() {
			let me = this
			this.path = []
			this.markers = []
			this.markersSw = []
			const points = await this.filterTracking;
			let firstPoint = {}
			let lastPoint = {}

			firstPoint = points[0]
			lastPoint = (points.length == 0) ? points[0]  : points[points.length - 1]
			/* if (points.length == 0) {
				firstPoint = {
					latitude: this.center.lat,
					longitude: this.center.lng,
					date: this.startingDate
				}
				lastPoint = {
					latitude: this.center.lat,
					longitude: this.center.lng,
					date: this.endingDate
				}
			} else {
				firstPoint = await points[0]
				lastPoint = await points[points.length - 1]
			} */
			let firstDate = new Date(firstPoint.date)
			let lastDate = new Date(lastPoint.date)
			this.center = {
				lat: parseFloat(firstPoint.latitude),
				lng: parseFloat(firstPoint.longitude)
			}
			this.markers.push({
				position: { lat: parseFloat(firstPoint.latitude), lng: parseFloat(firstPoint.longitude) },
				timestamp: firstDate,
				icon: { url: ('http://maps.google.com/mapfiles/ms/icons/green-dot.png'), }

			},)
			this.markers.push({
				position: { lat: parseFloat(lastPoint.latitude), lng: parseFloat(lastPoint.longitude) },
				timestamp: lastDate,
				icon: { url: ('http://maps.google.com/mapfiles/ms/icons/red-dot.png'), }
			},)


console.log(points[0])
console.log(points[length-1])

			points.forEach(function (item) {
				me.path.push({
					lat: parseFloat(item.latitude),
					lng: parseFloat(item.longitude),
					date: item.date
				})

				if (item.sw && item.sw === true) {
					me.markersSw.push({
						_id: item._id,
						position: { lat: parseFloat(item.latitude), lng: parseFloat(item.longitude) },
						timestamp: item.timestamp,
						icon: { url: ('http://maps.google.com/mapfiles/ms/icons/yellow-dot.png'), }
					},)
				}
			})
		},

	},
	computed: {
		lineSymbol() {
			return google.maps.SymbolPath.FORWARD_CLOSED_ARROW
		},
		startingDateFormatted() {
			return dayjs(this.startingDate).format('MM/DD/YYYY')
		},
		endingDateFormatted() {
			return dayjs(this.endingDate).format('MM/DD/YYYY')
		},
/* 		showFilteredTracking() {
			return this.filterTracking.sort((b, a) => (a.timestamp > b.timestamp) ? 1 : ((b.timestamp > a.timestamp) ? -1 : 0))
		} */
	}

}
</script>

<style>
.v-select__slot {
	position: relative;
	align-items: center;
	display: flex;
	max-width: 100%;
	min-width: 0;
	width: 80px;
}

.lds-ripple {
	display: inline-block;
	position: relative;
	width: 280px;
	height: 280px;
}

.lds-ripple div {
	position: absolute;
	border: 4px solid #fff;
	opacity: 1;
	border-radius: 50%;
	animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

.lds-ripple div:nth-child(2) {
	animation-delay: -0.5s;
}

@keyframes lds-ripple {
	0% {
		top: 136px;
		left: 136px;
		width: 0;
		height: 0;
		opacity: 1;
	}

	100% {
		top: 0px;
		left: 0px;
		width: 272px;
		height: 272px;
		opacity: 0;
	}
}
</style>