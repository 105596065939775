<template>
		<v-container fluid class="py-0 mt-0" >
			<v-row style="background: #37474F" class="mb-5">
				<v-col cols="12" color="blue-grey" class="px-5 white--text text-left">
					<v-icon  @click="goDevices()" class="mr-2 white--text" large >mdi-chevron-double-left</v-icon> GO BACK
				</v-col>
			</v-row>
			<v-row>
				<v-col>
					<div class="etiqueta1 pt-5">	
						<div class="text-center">
							<img class="mt-3" src="/img/imei-activation-number.png" width="420px"/>
							<h1 class="font-weight-black">{{ imeiFormat() }}</h1>
								<img src="/img/input-numbers.png" width="300px"/>
						</div>
						<div class="d-flex justify-space-around mt-2">
							<div>
								<img class="mt-2" src ="/img/logo-locmarine.png" width="300"/>
								<div class="caption text-right">SN: {{serial }} </div>
							</div>
							<div>
								<div class="qr2">
									<qr-code 
										:size="100"
										:text="imei"
										color="#000"
										bg-color="#fff" 
										error-level="L" />								
								</div>	
							</div>
						</div>
					</div>
					<div class="ml-2 mt-4">
						<v-btn dark @click.stop="printQr()">PRINT</v-btn>
					</div>
				</v-col>
				<!-- <v-col>
					<div class="etiqueta2 background_etiqueta2 mx-auto font_container">
						<div class="">
							<div class="font_serial">
								<vue-barcode  :value="serial" :options="{ displayValue: true }"></vue-barcode>
							</div>
						</div>
					</div>
					<div class="ml-5">
						<v-btn dark @click.stop="printSerial()">PRINT</v-btn>
					</div>
				</v-col> -->
			</v-row>
		</v-container>

</template>

<script>
	import VueBarcode from '@chenfengyuan/vue-barcode';
	export default {
		name: 'AdminLabels',
		components: {VueBarcode},
		data() {
			return {
			}
		},
		mounted(){
		},
		methods: {
			printQr(){
				this.$router.push({name: "PrintLabelQr", params: { imei: this.imei, serial: this.serial}})
			},
			printSerial(){
				this.$router.push({name: "PrinterLabelSerial", params: { serial: this.serial}})
			},
			goDevices(){        
        this.$router.push({name: "AdminDevices"})
      },
			imeiFormat(){
				const imeiFull = this.$route.params.imei;

				const imeiSpace = `${imeiFull.substring(0, 4)} ${imeiFull.substring(4, 8)} ${imeiFull.substring(8, 12)} ${imeiFull.substring(12,15)}`;

				return imeiSpace;
			}

		},
		computed: {
			imei(){
				return this.$route.params.imei;
			},
			serial(){
				return this.$route.params.serial;
			}
		}
	}
</script>

<style lang="scss" scoped>

	.etiqueta1 {
		width: 455px;
		height: 270px;
		border-style: solid;
		border: 1 px;
		line-height: 1.0 !important;
		border-style: dashed;		
	}

	h1 {
		font-size: 2.700rem !important;
    // font-weight: 900 !important;
	}
	.font_imei {
		text-align: center;
		font-size: 2.45rem !important;
    font-weight: 900 !important;
	}
	.qr {
		float: right;
		margin-right: 20px;
		position: relative;
		margin-top: -10px;
	}

	.etiqueta2 {
		width: 728px;
		height: 478px;
		border-style: solid;
		border: 1 px;
		border-color: rgb(191, 194, 194);
		border-style: dashed;
		
	}
	.background_etiqueta2 {
		background-image: url("/img/etiqueta-2.jpg");
	}
	.font_container {
		position: relative;
	}
	.font_serial {
		position: absolute;
    bottom: 0;
		font-size: 20px;
		margin-bottom: 34px;
		margin-left: 45px;
	}

</style>